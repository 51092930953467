import React from 'react';
import './App.css';
import MiddleSection from './components/middle';
import Discord from './assets/discord.svg';
import Mail from './assets/mail.svg';
import X from './assets/x.svg';
import './styles/theme/variables.css'; // Importing variables.css
import './styles/global.css'; 

function App() {
  return (
    <div className="App">
      <div className="box-container first-div">
        <h1 className="main-heading">Designer who write code</h1>
        <p className="subtext">
        Based out of India. I live by the philosophy of experimenting around & decoding pieces. That’s how you truly learn.
        </p>
      </div>
      <div className="primary-button">
        <span>Resume</span>
      </div>
      <MiddleSection />
      <div className="bottom">
        <h5 className="version">v1.0</h5>
        <div className="bottom-con">
          <img src={Discord} alt='discord logo' />
          <img src={Mail} alt='mail' />
          <img src={X} alt='Twitter' />
        </div>
      </div>
    </div>
  );
}

export default App;
